<template>
  <base-section
    id="turnkey"
    class="px-5"
  >
    <base-section-heading
      title="TURNKEY PROJECT MANAGEMENT SPECIALIST FOR GLOVE MANUFACTURING LINE"
    >
    </base-section-heading>
      <v-row>
        <v-col
          v-for="(info, n) in information"
          :key="n"
          cols="12"
          md="3"
          sm="12"
        >
          <v-card
            class="mx-auto black--text"
            :min-height="$vuetify.breakpoint.mdAndUp ? 330 : 220"
          >
            <v-img
              contain
              :src="info.src"
              :height="$vuetify.breakpoint.mdAndUp ? 200 : 100"
            ></v-img>

            <v-card-title>
              {{info.title}}
            </v-card-title>
            <v-card-text
              class="black--text v-space_control"
            >
              {{info.text}}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionTurnkey',
    data () {
      return {
        information: [
          {
            title: 'Design & Fabrication',
            text: 'Production lines, factory utilities system',
            src: require('@/assets/design.png'),
          },
          {
            title: 'Installation',
            text: 'Production lines, factory utilities system',
            src: require('@/assets/installation.png'),
          },
          {
            title: 'Commissioning',
            text: 'Production line startup optimization and debugging',
            src: require('@/assets/commissioning.png'),
          },
          {
            title: 'Production',
            text: 'Advance process technology and quality assurance with compliance of international standards',
            src: require('@/assets/production.png'),
          },
        ],
      }
    },
  }
</script>
<style type="text/css">
.v-card__text {
  letter-spacing: 0.0rem;
}
</style>
